.timings {
  justify-items: left;
  display: grid;
  grid-template-rows: repeat(10, minmax(0, 1fr));
  grid-gap: 0.2vw;
  overflow: visible;
  width: 100%;
  text-align: center;
}

.timing {
  box-shadow: var(--dropShadow);
  // border-radius: 0.2vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5vw;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  max-height: 1.8vw;
  padding: 0 0.2vw 0 0.2vw;

  .rank {
    width: 1.3vw;
    font-size: 1.3vw;
    text-align: center;
  }

  .name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    font-size: 0.7vw;
    font-weight: 500;
    line-height: 1.1; /* Increased line height */
    text-align: left;
    flex: 1;
    box-sizing: border-box;
    margin: 0;
  }

  .time {
    min-width: 3vw;
    font-size: 0.8vw;
    font-weight: 500;
    line-height: 0;
  }

  .athletesResultsBoxNumber {
    max-height: 1.9vw;
    padding-left: 0.3vw;
    font-size: 1.6vw;
    text-align: left;
    letter-spacing: -0.1vw;
  }
}
