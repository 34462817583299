$width-one-desktop: 1200px;
$width-desktop-central: 1200px;
$width-two-tablet: 768px;
$width-three-mobile: 425px;

:root {
  // sizing
  --width-one-desktop: 1200px;
  --width-desktop-central: 1000px;
  /* Light Mode Colors */
  --colour-highlight: rgb(30, 142, 146);
  --colour-primary: #ffffff;
  --colour-element: rgb(34, 37, 41);
  --colour-secondary: #f7f7f7;
  --colour-transparent: rgba(0, 0, 0, 0.374);
  --colour-border: #cfcfcf;
  --colour-divider: #f2f2f2;
  --colour-nav: rgb(54, 67, 70);
  --background-gradient: linear-gradient(
    135deg,
    rgb(40, 112, 123) 0%,
    rgb(41, 123, 138) 50%,
    rgb(40, 98, 106) 100%
  );
  --background-gradient-alternative: linear-gradient(
    137deg,
    #51d6d8 0%,
    #00445b 100%
  );
  --colour-text-primary: rgb(29, 29, 31);
  --colour-text-secondary: rgb(62, 62, 64);
  --colour-text-inverse: #ffffff;
  --text-gradeint: linear-gradient(90deg, rgb(2, 38, 64), rgb(87, 134, 171));
  --invert: 0;
  --transition: 0.22s ease;
  --dark-background: rgba(0, 0, 0, 0.4);
  --blur-background: blur(4px);

  /* Primary colors */
  --blue: rgb(0, 122, 255);
  --green: #3aba35;
  --indigo: rgb(88, 86, 214);
  --orange: rgb(255, 149, 0);
  --pink: rgb(255, 45, 85);
  --purple: rgb(175, 82, 222);
  --red: rgb(237, 49, 39);
  --teal: rgb(90, 200, 250);
  --yellow: rgb(255, 204, 0);
  --colour-rp3: rgb(58, 196, 231);

  /* Neutral colors */
  --gray: #8e8e93;
  --gray2: #636366;
  --gray3: #48484a;
  --gray4: #3a3a3c;
  --gray5: #2c2c2e;
  --gray6: #1c1c1e;

  /* Semantic colors */
  --primary-background: #ffffff;
  --primary-text: rgb(9, 9, 9);
  --secondary-text: #6c6c70;
  --inverse-text: #ffffff;

  /* Non-color related variables */
  --font-primary: "Proxima Nova", sans-serif;
  --font-secondary: "system-ui", -apple-system, "system-ui", "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "System Default", sans-serif;
  --titlebar-height: 50px;
  --padding-height: 15px;
  --padding-width: 17px;
  --padding-main: var(--padding-height) var(--padding-width);
  --padding-header: 11px 17px 11px 22px;
  --padding-secondary: 6px 8px;
  --gap-main: 10px;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  --title-colour: #ffffffaf;
  --border: 2px solid var(--colour-divider);
  --border-bold: 3px solid var(--colour-divider);
  --border-radius: 5px;
  --header-height: 80px;
}

@media (max-width: 768px) {
  :root {
    --font-size: 16px;
    --padding-main: 10px 15px;
  }
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     /* Dark Mode Colors */
//     --colour-highlight: #00ada5;
//     --colour-element: rgb(229, 229, 234);
//     --colour-primary: #2c2c2e;
//     --colour-secondary: #1c1c1e;
//     --colour-border: #48484a;
//     --colour-divider: rgb(28, 28, 28);
//     --colour-nav: rgb(229, 229, 234);
//     --background-gradient: linear-gradient(
//       135deg,
//       rgb(0, 38, 46) 0%,
//       rgb(1, 48, 59) 50%,
//       rgb(0, 28, 34) 100%
//     );
//     --colour-text-primary: rgb(255, 255, 255);
//     --colour-text-secondary: rgb(231, 231, 240);
//     --colour-text-inverse: rgb(29, 29, 31);
//     --invert: 1;

//     /* Primary colors - slightly muted for dark mode */
//     --blue: rgb(10, 132, 255);
//     --green: #32b82c;
//     --indigo: rgb(94, 92, 230);
//     --orange: rgb(255, 159, 10);
//     --pink: rgb(255, 55, 95);
//     --purple: rgb(191, 90, 242);
//     --red: rgb(255, 69, 58);
//     --teal: rgb(100, 210, 255);
//     --yellow: rgb(255, 214, 10);

//     /* Neutral colors - inverted for dark mode */
//     --gray: #98989d;
//     --gray2: #aeaeb2;
//     --gray3: #c7c7cc;
//     --gray4: #d1d1d6;
//     --gray5: #e5e5ea;
//     --gray6: #f2f2f7;

//     /* Semantic colors */
//     --primary-background: #1c1c1e;
//     --primary-text: rgb(229, 229, 234);
//     --secondary-text: #aeaeb2;
//   }
// }
