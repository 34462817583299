// global.scss

// Variables
@import "./variables.scss";

@font-face {
  src: url("https://uploads-ssl.webflow.com/60730491703f6d4b93b69210/60ec3d0d995cde39459ea27d_Proxima%20Nova%20Regular.woff2");
  font-display: swap;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
}

@font-face {
  src: url("https://uploads-ssl.webflow.com/60730491703f6d4b93b69210/60ec3d0c5015ff7a6d3b806f_Proxima%20Nova%20Light.woff2");
  font-display: swap;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 300;
}

@font-face {
  src: url("https://uploads-ssl.webflow.com/60730491703f6d4b93b69210/60ec3d0c00a527d05fe686be_Proxima%20Nova%20Semibold.woff2");
  font-display: swap;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
}

@font-face {
  src: url("https://uploads-ssl.webflow.com/60730491703f6d4b93b69210/6131d6637850005ca3f49354_Tungsten-Medium.ttf");
  font-display: swap;
  font-family: "Tungsten";
  font-style: normal;
  font-weight: 500;
}

@font-face {
  src: url("https://uploads-ssl.webflow.com/60730491703f6d4b93b69210/6131d663ed353b8e22544be8_Tungsten-Semibold.ttf");
  font-display: swap;
  font-family: "Tungsten";
  font-style: normal;
  font-weight: 600;
}

@font-face {
  src: url("https://uploads-ssl.webflow.com/60730491703f6d4b93b69210/6131d663d8749f19a33f8f93_Vitesse-Medium.ttf");
  font-display: swap;
  font-family: "Vitesse";
  font-style: normal;
  font-weight: 500;
}

@font-face {
  src: url("https://uploads-ssl.webflow.com/60730491703f6d4b93b69210/6131d663ddcfd6cf934c4a51_Vitesse-Light.ttf");
  font-display: swap;
  font-family: "Vitesse";
  font-style: normal;
  font-weight: 300;
}

@font-face {
  src: url("https://uploads-ssl.webflow.com/60730491703f6d4b93b69210/607976acab5fde18b3766721_proximanovablack.otf");
  font-display: swap;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 900;
}

@font-face {
  src: url("https://uploads-ssl.webflow.com/60730491703f6d4b93b69210/60ee870f6c55535a05f13f2e_FontAwesome.otf");
  font-display: swap;
  font-family: "Fontawesome";
  font-style: normal;
  font-weight: 400;
}

html {
  scroll-snap-type: y mandatory;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  background-color: black;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen";
  font-weight: 400;
  line-height: 1.5;
  color: var(--colour-text-primary);
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-section {
  scroll-snap-align: start;
}

.no-scroll {
  overflow: hidden;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  min-width: 70px;
  min-height: 35px;
  padding: var(--padding-secondary);
  transition: all var(--transition);
  background-color: var(--colour-primary);
  border: 1.6px solid var(--colour-border);
  border-radius: 4px;
  font-size: 14px;
  font-family: inherit;
  font-weight: 600;
  color: var(--colour-text-primary);
  white-space: nowrap;
}

button:hover {
  filter: brightness(0.96);
  cursor: pointer;
}

li {
  list-style: none;
}

img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
}

a {
  padding: 5px;
  transition: background-color 200ms ease;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: inherit;
  text-decoration: none;
}

a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

textarea {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  resize: vertical;
  padding: 10px;
  border: 1px solid grey; /* Keep the basic border */
  border-radius: 8px;
  font-size: 16px;
}

label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}

input,
select,
option {
  box-sizing: border-box;
  padding: 8px;
  background-color: var(--colour-primary);
  border: 1px solid var(--colour-border);
  border-radius: 2px;
  font-size: 16px;
  font-family: inherit;
  color: var(--colour-text-primary);
}

input[type="color"] {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  margin: 0;
  padding: 0;
  background-color: rgb(0, 0, 0, 0);
  cursor: pointer;
  border: 1px solid var(--colour-primary);
  border-radius: 50%;
}

input[type="prefChooser"] {
  box-shadow: 0px 0px 20px #18181833;
  display: grid;
  gap: 5px;
  padding: 15px;
  background-color: var(--colour-primary);
  border-radius: 3px;
  font-family: inherit;
}
input[type="checkbox"] {
  aspect-ratio: 1/1;
  cursor: pointer;
  border: none;
  border-radius: 50%;
}

/* Chrome specific styles */
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  letter-spacing: -0.02em;
  color: inherit;
}

h1 {
  font-size: 2vw;
  font-family: var(--font-primary);
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.03em;
}

h2 {
  font-size: 1vw;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

h3 {
  font-size: 0.8vw;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -0.01em;
}

h4 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.3;
}

p {
  line-height: 1.7;
  font-size: 1.05rem;
  font-weight: 440;
  margin-bottom: 1rem;
  max-width: 65ch;
}

blockquote {
  margin: 0 0 2rem 0;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5;
  color: inherit;
  font-style: italic;
  position: relative;
  z-index: 1;

  p {
    margin: 0;
  }
}

strong {
  font-weight: 600;
}

em {
  font-style: italic;
}

@media screen and (max-width: $width-two-tablet) {
  h1 {
    font-size: 2.4rem;
    line-height: 1.1;
  }

  h2 {
    font-size: 1.8rem;
    line-height: 1.2;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
  }
}

@media screen and (max-width: $width-three-mobile) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }
}

pre {
  page-break-inside: avoid;
  display: block;
  overflow: auto;
  max-width: 100%;
  margin: 0 0 1.5rem 0;
  padding: 1.2em 1.5em;
  background: var(--secondary-background);
  border-left: 5px solid var(--orange);
  border-radius: 8px;
  font-size: 15px;
  font-family: "SF Mono", Menlo, Monaco, Consolas, monospace;
  line-height: 1.6;
  word-wrap: break-word;
}

code {
  font-family: "SF Mono", Menlo, Monaco, Consolas, monospace;
  font-weight: 500;
  font-size: 0.9em;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 0.2em 0.4em;
  border-radius: 4px;
}

div {
  box-sizing: border-box;
}

th {
  padding: 20px;
  background-color: var(--colourBackgroundAccent);
  font-size: 20px;
  color: var(--colourText);
  text-align: center;
}

td {
  padding: 12px;
  text-align: center;
}

:root {
  --primary-color: #ff0000;
  --secondary-color: #00ff00;
  --font-size: 16px;
  --background-color: rgb(246, 245, 243);
  --background-color-dark: rgb(255, 255, 255);
  --dark-font-color: #070707;
  --text-shadow: 0 0 0.5vw rgba(0, 0, 0, 0.4);
  --gap: 0.6vw;
  --spaceGrey: #1d1d1f;
  --dropShadow: 0 0vw 0.5vw rgba(0, 0, 0, 0.1);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background {
  position: fixed;
  opacity: 1;
  z-index: 1;
  // background: linear-gradient(
  //   135deg,
  //   #f28d00d8 0%,
  //   #ffaa00 90%,
  //   #21468b 90%,
  //   #21468b 93.33%,
  //   #ffffff 93.33%,
  //   #ffffff 96.66%,
  //   #ae1c28 96.66%,
  //   #ae1c28 100%
  // );
  background-color: var(--colour-primary);
  width: 100vw;
  height: 100vh;
}

.sponsors {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-evenly;
  place-items: center;
  border-radius: var(--gap);
  width: 100%;
  font-size: 2.2vw;
  text-align: center;
  position: relative;
  z-index: 2;
  padding-bottom: 1vw;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.header {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 2.2vw;
  text-align: center;
}
