.overall {
  display: flex;
  position: absolute;
  flex: 1;
  flex-direction: column;
  gap: var(--gap);
  z-index: 99;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--gap);
    box-sizing: border-box;

    overflow: hidden;
    position: relative;
    z-index: 99;
    padding: var(--gap);
  }
}

.headerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.maleFemaleBox {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}

.mixedBox {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: var(--gap);
  width: 100%;
}

.genderBox {
  width: 50%;
}

.genderHeader {
  font-size: 1.4vw;
  text-align: center;
}

.timingsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 0.5vw;
  padding-right: 1vw;
  min-height: none;
  font-size: 0.8vw;
}

.rankings {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-width: 2.7vw;
  height: auto;
}

.nameTimeContainer {
  display: grid;
  align-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 7.3vw;
}

.names {
  flex: auto;
  white-space: nowrap;
}

.times {
  flex: auto;
  min-width: 3vw;
  text-align: right;
}

.unit {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  min-height: 0px;
  text-align: center;
}

.categoryHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: var(--gap);
  padding-bottom: var(--gap);
  width: 100%;
  font-size: 1.6vw;
}

.leaderboards {
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
  column-gap: var(--gap);
  row-gap: var(--gap);
  width: 100%;
  height: calc(100% - 3vw);
}

.leaderboard {
  backdrop-filter: blur(2vw);
  width: 100%;
  height: 100vh;
  min-height: 7vw;
  overflow: hidden;
}

.results {
  display: grid;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: var(--gap);

  .boat {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    box-shadow: var(--dropShadow);
    // border-radius: 0.5vw;
    background-color: white;
    width: 100%;
    min-height: 22vw;
    flex: 1;

    &-header {
      box-sizing: border-box;
      background-color: var(--colour-secondary);
      padding: 0.4vw;
      padding-bottom: 0.3vw;
      font-size: 1vw;
      text-align: center;
    }

    &-container {
      grid-gap: var(--gap);
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-auto-rows: 1fr; /* Set the rows to have equal height */
      padding: var(--gap);
      overflow: auto;
      height: 20px;
      flex: 1;
    }

    .category {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      backdrop-filter: blur(0.2vw);
      width: 100%;
      min-height: 0;

      &-subheader {
        padding: 0.4vw 0vw;
        text-align: center;
      }
    }
  }

  &-stats {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-rows: 1fr; /* Set the rows to have equal height */
    flex-grow: 1;
    align-items: top;
    justify-items: center;
    gap: var(--gap);
    width: 100%;
    text-align: center;
  }
}

.categoryContainerEight {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  flex-grow: 0;
  align-items: top;
  justify-items: center;
  width: 100%;
  text-align: center;
}

.testContainer {
  grid-gap: 50px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  box-sizing: border-box;
  padding: 3vw;
  width: 100vw;
  height: 100vh;
}

.windowInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  background-color: yellow;
}

.windowContainer {
  display: Grid;
  grid-template-columns: repeat(5, 1fr);
}
